export abstract class UavRouteNames {
  static readonly overview: string = 'uav.overview';
  static readonly insights: string = 'uav.insights';
  static readonly cases: string = 'uav.cases';
  static readonly faults: string = 'uav.faults';
  static readonly insightDetails: string = 'uav.insightDetails';
  static readonly caseDetails: string = 'uav.caseDetails';
  static readonly workOrders: string = 'uav.workOrders';
  static readonly workOrderDetails: string = 'uav.workOrderDetails';
  static readonly remoteDiagnosticsUav: string = 'uav.remoteDiagnosticsUav';
}

export abstract class UavRouteParameterNames {
  static readonly assetId: string = 'assetId';
  static readonly caseID: string = 'caseId';
  static readonly assetInsightID: string = 'assetInsightId';
  static readonly workOrderID: string = 'workOrderID';
}
