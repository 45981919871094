import { computed } from 'vue';
import { useLocalStorage } from '@/utils/localStorageUtils';
import { filterUtils } from '@/utils/filterUtils';
import { useAPI } from '@/api/client/apiClient';
import type { Filter } from '@/features/userSavedFilters/types/userSavedFiltersTypes';
import useUserFiltersStore from '@/features/userSavedFilters/store/userFiltersStore';
import useGlobalFiltersStore from '@/features/userSavedFilters/store/globalFiltersStore';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';

export function useInitialize() {
  const api = useAPI();
  const tenantStore = useTenantStore();
  const userFiltersStore = useUserFiltersStore();
  const globalFiltersStore = useGlobalFiltersStore();

  const name = computed(() => (tenantStore.config as any)?.taxonomy?.name);
  const version = computed(() => (tenantStore.config as any)?.taxonomy?.version);
  // useLocalStorage().enableCase =tenantStore.enableCaseFeature.toString();
  /**
   * Sets assetFilters
   * @returns void
   */
  async function setAssetFilters(filter?: any) {
    // const payload: Assets['AssetFiltersRequest'] = {
    const payload: any = {
      filters: { ...(filter?.filter || {})  },
      filterId: userFiltersStore.filterId,
      attributes: userFiltersStore.attributes,
    };

    return api.UserSavedFilters.getAssetsByFiltersAndAttributesPost_1(payload)
      .then((response: any) => {
        const asset = filterUtils().generateAssetFilters(response.data.filters, tenantStore.config.filters);
        return asset;
      })
      .catch((error: any) => {
        console.error(error);
        return [{}];
      });
  }

  /**
   *
   * @param initialFilter
   * @param asset
   * @param filterTaxonomy
   * initializes the user, global filter state and generates asset filters
   */
  async function initializeFilterState(filterTaxonomy?: Filter[], filter?: any) {
    const allAssetsFilter = await userFiltersStore.getUserSavedFilters.find(
      (filter: any) => filter.filterName === 'All Assets'
    );

    const newFilter = {
      ...allAssetsFilter,
      filters: {
        attributeFilters: {
          patternFilters: {},
          collectionFilters: { ...(filter?.filter || {})  },
        },
      },
    };

    await userFiltersStore.updateSavedFilter(allAssetsFilter.userSavedFilterId, newFilter);

    if (allAssetsFilter) {
      await userFiltersStore.updateUserFilters({
        taxonomy: filterTaxonomy || [],
        filter: { ...(filter?.filter || {})  },
        filterId: allAssetsFilter.userSavedFilterId,
        attributes: tenantStore.config.filters,
      });

      const asset: any = await setAssetFilters(filter);
      await userFiltersStore.updateUserFilters({
        asset: asset,
      });

      globalFiltersStore.updateGlobalFilters({
        asset: asset,
        taxonomy: filterTaxonomy || [],
        filter: { ...(filter?.filter || {})  },
        filterId: allAssetsFilter.userSavedFilterId,
        attributes: tenantStore.config.filters,
      });

      // await userFiltersStore.setTemporaryTaxonomy(toRaw(globalFiltersStore.taxonomy), toRaw(globalFiltersStore.asset));
      checkForInvalidFilters(userFiltersStore.savedFilters, userFiltersStore.taxonomy, userFiltersStore.asset, filter);
    }
  }
  /**
   *
   * @param filter
   * @param initialFilter
   * @param asset
   * @param filterTaxonomy
   * calls user saved filters and sets the initial filterId
   */

  async function checkForInvalidFilters(savedFilters: any, taxonomy: any, asset: any, filter: any) {
    function combineArrays(arr1: any, arr2: any) {
      const combined = [...arr1, ...arr2];
      const uniqueMap = new Map();

      for (const obj of combined) {
        if (obj.options && obj.options.length > 0) uniqueMap.set(obj.title, obj);
      }
      return Array.from(uniqueMap.values());
    }

    const tree = combineArrays(taxonomy, asset);
    const invalidFiltersArray = await filterUtils().getInvalidFilters(savedFilters, tree);

    const invalidFilters = JSON.parse(JSON.stringify(invalidFiltersArray));
    userFiltersStore.updateInvalidFilters(invalidFilters);
  }

  async function initializeUserSavedFilters(filter?: any, filterTaxonomy?: Filter[]) {
    await userFiltersStore.updateUserFilters({
      permissions: { ...(filter?.filter || {}) },
    });
    try {
      await userFiltersStore.updateUserSavedFilters();
      const getFilterByName = (name: string) =>
        userFiltersStore.getUserSavedFilters.find((filter: any) => filter.filterName === name);
      const defaultFilterExists = () => !!userFiltersStore.getUserSavedFilters.find((filter: any) => filter.isDefault);
      const allAssetsFilterAction = async () => {
        const allAssetsFilter = getFilterByName('All Assets');

        if (!allAssetsFilter) {
          await userFiltersStore.postSavedFilter({
            filterName: 'All Assets',
            description: 'All Assets',
            isDefault: !defaultFilterExists(),
            isHidden: false,
            filters: {
              attributeFilters: {
                patternFilters: {},
                collectionFilters: { ...(filter?.filter || {})  },
              },
            },
          });
          await userFiltersStore.updateUserSavedFilters();
          const newlyCreatedAllAssetsFilter = getFilterByName('All Assets');
          await userFiltersStore.updateCurrentSelectedFilter(newlyCreatedAllAssetsFilter);
          await userFiltersStore.updateDefaultSelectedFilter(newlyCreatedAllAssetsFilter);
          await userFiltersStore.updateLastFilter(newlyCreatedAllAssetsFilter);
        } else {
          const updatedFilter = {
            ...allAssetsFilter,
            filters: {
              attributeFilters: {
                patternFilters: {},
                collectionFilters: { ...(filter?.filter || {})  },
              },
            },
          };

          await userFiltersStore.updateSavedFilter(allAssetsFilter.userSavedFilterId, updatedFilter);
          await userFiltersStore.updateUserSavedFilters();
          const updatedAllAssetsFilter = getFilterByName('All Assets');
          await userFiltersStore.updateLastFilter(updatedAllAssetsFilter);
          await userFiltersStore.updateUserSavedFilters();
          await userFiltersStore.updateTotalAssetsCount();
        }
      };

      const deleteLastFilterAction = async () => {
        const lastFilter = getFilterByName('last');
        if (lastFilter) {
          userFiltersStore.deleteSavedFilter([lastFilter.userSavedFilterId]);
        }
      };

      await allAssetsFilterAction();
      if (defaultFilterExists()) {
        const defaultFilter = userFiltersStore.getUserSavedFilters.find((filter: any) => filter.isDefault);
        await userFiltersStore.updateCurrentSelectedFilter(defaultFilter);
        await userFiltersStore.updateDefaultSelectedFilter(defaultFilter);
      } else {
        const allAssetsFilter = getFilterByName('All Assets');
        await userFiltersStore.updateCurrentSelectedFilter(allAssetsFilter);
        await userFiltersStore.updateDefaultSelectedFilter(allAssetsFilter);
        await userFiltersStore.updateSavedFilter(allAssetsFilter.userSavedFilterId, {
          ...allAssetsFilter,
          isDefault: true,
        });
      }
      await deleteLastFilterAction();
      await initializeFilterState(filterTaxonomy ?? [], filter ?? {});
    } catch (error) {
      console.error(error);
    }
  }

  async function initializeFiltersCommercial() {
    if (!name.value || !version.value) {
      // console.error('Missing name or version for taxonomy API call');
      userFiltersStore.updateUserFilters({
        asset: [],
        taxonomy: [],
        loadingTaxonomies: false,
        filter: undefined,
        filterId: undefined,
        userSavedFilterId: undefined,
        attributes: [],
        savedFilters: [],
      });

      await initializeUserSavedFilters();

      return;
    }

    await api.Taxonomy.getTaxonomies(name.value, version.value).then(async (response) => {
      if (
        (response.data.userPermissions.tenant.defaultUserPermissions.defaultGlobalPermissions !== 'ALL' &&
          !response.data.userPermissions.permissions) ||
        (response.data.userPermissions.permissions && response.data.userPermissions.permissions.length === 0)
      ) {
        // console.log('No permissions');
        userFiltersStore.updateUserFilters({
          asset: [],
          taxonomy: [],
          loadingTaxonomies: false,
          filter: undefined,
          filterId: undefined,
          userSavedFilterId: undefined,
          attributes: [],
          savedFilters: [],
        });
        globalFiltersStore.updateGlobalFilters({
          asset: [],
          taxonomy: [],
          loadingTaxonomies: false,
          filter: undefined,
          filterId: '',
          attributes: [],
        });
        return;
      }
      let taxonomy: any;
      let filter: any;
      if (
        (response.data.userPermissions.tenant.defaultUserPermissions.defaultGlobalPermissions === 'ALL' &&
          (!response.data.tree || response.data.tree.length === 0 || !response.data.userPermissions.permissions)) ||
        (response.data.userPermissions.tenant.defaultUserPermissions.defaultGlobalPermissions === 'NONE' &&
          (!response.data.tree || response.data.tree.length === 0 || !response.data.userPermissions.permissions) &&
          response.data.userPermissions.permissions &&
          response.data.userPermissions.permissions.length === 1 &&
          ((response.data.userPermissions.permissions[0].taxonomyPath &&
            response.data.userPermissions.permissions[0].taxonomyPath.length === 0) ||
            !response.data.userPermissions.permissions[0].taxonomyPath))
      ) {
        // console.log('default permissions');
        const allPermissionFilter: any = await api.Taxonomy.getTaxonomies(name.value, version.value).catch((error) => {
          console.error(error);

        });
        filter = filterUtils().allPermissionsToFilters(allPermissionFilter.data.tree.tree);
        taxonomy = filterUtils().processTaxonomy(allPermissionFilter.data.tree).result;
      }
      if (
        response.data.tree &&
        response.data.tree.length !== 0 &&
        response.data.userPermissions.permissions &&
        response.data.userPermissions.permissions.length === 1 &&
        ((response.data.userPermissions.permissions[0].taxonomyPath &&
          response.data.userPermissions.permissions[0].taxonomyPath.length === 0) ||
          !response.data.userPermissions.permissions[0].taxonomyPath)
      ) {
        // console.log('all permissions');
        filter = filterUtils().allPermissionsToFilters(response.data);
        taxonomy = filterUtils().processTaxonomy(response.data.tree).result;
      }
      if (
        response.data.tree &&
        filter === undefined &&
        response.data.userPermissions.permissions.length >= 1 &&
        response.data.userPermissions.permissions[0].taxonomyPath &&
        response.data.userPermissions.permissions[0].taxonomyPath.length !== 0
      ) {
        // console.log('specific permissions');
        filter = filterUtils().permissionsToFilters(response.data);
        taxonomy = filterUtils().processTaxonomy(response.data.tree).result;
      }

      const filterTaxonomy = filterUtils().generateTaxonomyFilters(taxonomy);
      await initializeUserSavedFilters(filter, filterTaxonomy);
    });
  }

  async function initializeFiltersFederal() {
    const allPermissionFilter: any = await api.Taxonomy.getTaxonomies(name.value, version.value).catch((error) => {
      console.error(error);
    });
    const filter = filterUtils().allPermissionsToFilters({
      tree: allPermissionFilter.data,
    });
    const taxonomy = filterUtils().processTaxonomy(allPermissionFilter.data).result;
    const filterTaxonomy: Filter[] = filterUtils().generateTaxonomyFilters(taxonomy);
    await initializeUserSavedFilters(filter, filterTaxonomy);
  }

  /**
   *
   * @param tenant
   * sets tenant config into tenantStore.config
   */

  async function init() {
    const tenantStore = useTenantStore();

    if (!name.value) {
      await tenantStore.setTenantFeatureSubscriptions();
      await tenantStore.setTenantConfig();
      if (tenantStore.config.product === 'commercial') {
        await initializeFiltersCommercial();
      } else {
        await initializeFiltersFederal();
      }
    }
  }
  return { init };
}
