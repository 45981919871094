import { keycloak } from '@/api/auth';
import { cookieUtils } from '@/utils/cookieUtils';
import { useLocalStorage } from '@/utils/localStorageUtils';
import { hosts } from '@/features/auth/constants/Hosts';
import useAuthStore from '@/features/auth/store/authStore';

const realm = cookieUtils().getCookie('realm');
const product = useLocalStorage().product;

export default function setupGoogleAnalyticsAndHotJar() {
  if (product === 'commercial') {
    const authenticated = useAuthStore();
    if (authenticated.isAuthenticated) {
      const tenant_code = authenticated.tenant;
      let uptaker = false;
      let user_id; //poorly named variable - this is the username or email address, not the UUID user_id
      let user_sub_id = '';
      if (authenticated.impersonator !== '') {
        user_id = authenticated.impersonator;
        user_sub_id = authenticated.impersonatorId; //the impersonator's UUID from keycloak
        uptaker = true;
      } else if (keycloak.tokenParsed?.email) {
        user_id = keycloak.tokenParsed?.email;
      } else if (keycloak.tokenParsed?.preferred_username) {
        user_id = keycloak.tokenParsed?.preferred_username;
      }

      if (!uptaker && user_id) {
        if (user_id.indexOf('@uptake.com')) {
          uptaker = true;
        }
      }

      if (user_sub_id == '' && keycloak.tokenParsed?.sub) {
        user_sub_id = keycloak.tokenParsed.sub; //the non-impersonated UUID from keycloak
        //we always prefer to have the impersonated value over the non-impersonated value for UUID for GA tracking.
      }

      //Begin Google Analytics 4 Implementation
      let ga4AccountId = '';
      if (hosts.prod === window.location.hostname) {
        // console.log('GA4 Prod Mode');
        ga4AccountId = 'G-X34SJKQBRJ';
      } else if (hosts.staging === window.location.hostname) {
        // console.log('GA4 Staging Mode');
        ga4AccountId = 'G-89KGRF7R3J';
      } else if (hosts.dev === window.location.hostname || hosts.local === window.location.hostname) {
        // console.log('GA4 Dev Mode');
        ga4AccountId = 'G-9TM4SV42RY';
      }

      const ga4Script = document.createElement('script');
      ga4Script.setAttribute('async', '');
      ga4Script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + ga4AccountId);
      document.head.appendChild(ga4Script);
      
      const ga4AttributeScript = document.createElement('script');
      const ga4AttributeScriptBody = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${ga4AccountId}', { 
          custom_map: { dimension1: 'tenant', dimension2: 'uptaker' }
        });
        gtag('event', 'custom_dimensions', {
          tenant: '${tenant_code}',
          uptaker: '${uptaker}'
        });
        gtag('config', '${ga4AccountId}', { user_id: '${user_sub_id}'});
      `;
      
      ga4AttributeScript.innerText = ga4AttributeScriptBody;
      document.head.appendChild(ga4AttributeScript);
      
      //End Google Analytics 4 Implementation

      //Begin Hotjar Implementation
      const hotjarScriptBody = `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1640992,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

      const tenantVariable = `var tenantId = '` + tenant_code + `';`;
      const hotjarAttributesScriptBody =
        `var userId = '` +
        user_sub_id +
        `' || null;
          window.hj('identify', userId, {
          'Tenant': '` +
        tenant_code +
        `',
          'Uptaker': '` +
        uptaker +
        `',
          'Username': '` +
        user_id +
        `'});`;

      const tenantVariableScript = document.createElement('script');
      tenantVariableScript.innerText = tenantVariable;
      document.head.appendChild(tenantVariableScript);

      if (hosts.prod === window.location.hostname) {
        const hotjarScript = document.createElement('script');
        hotjarScript.innerText = hotjarScriptBody;
        document.head.appendChild(hotjarScript);

        const hotjarAttributeScript = document.createElement('script');
        hotjarAttributeScript.innerText = hotjarAttributesScriptBody;
        document.head.appendChild(hotjarAttributeScript);
      } else if (hosts.staging === window.location.hostname) {
        const hotjarScript = document.createElement('script');
        hotjarScript.innerText = hotjarScriptBody;
        document.head.appendChild(hotjarScript);

        const hotjarAttributeScript = document.createElement('script');
        hotjarAttributeScript.innerText = hotjarAttributesScriptBody;
        document.head.appendChild(hotjarAttributeScript);
      } else if (hosts.dev === window.location.hostname || hosts.local === window.location.hostname) {
        const hotjarScript = document.createElement('script');
        hotjarScript.innerText = hotjarScriptBody;
        document.head.appendChild(hotjarScript);

        const hotjarAttributeScript = document.createElement('script');
        hotjarAttributeScript.innerText = hotjarAttributesScriptBody;
        document.head.appendChild(hotjarAttributeScript);
      }
    }
  } else if (product === 'federal') {
    // console.log('federal product mode - not adding GA scripts');
  }
}
