import { hosts } from '@/features/auth/constants/Hosts';
import { useLocalStorage } from '@/utils/localStorageUtils';
import { keycloak } from '@/api/auth';
import useAuthStore from '@/features/auth/store/authStore';

const product = useLocalStorage().product;

export default function setupWalkMe() {
  if (product === 'commercial') {
    const authenticated = useAuthStore();
    let uptaker = false;
    let username = '';

    if (authenticated.isAuthenticated) {
      const tenant_code = authenticated.tenant;
      uptaker = false;
      if (authenticated.impersonator !== '') {
        username = authenticated.impersonator;
        uptaker = true;
      } else if (keycloak.tokenParsed?.email) {
        username = keycloak.tokenParsed?.email;
      } else if (keycloak.tokenParsed?.preferred_username) {
        username = keycloak.tokenParsed?.preferred_username;
      }

      if (!uptaker && username) {
        if (username.indexOf('@uptake.com') !== -1) {
          uptaker = true;
        }
      }
    }

    const isDev =
      hosts.dev === window.location.hostname ||
      hosts.local === window.location.hostname ||
      hosts.staging === window.location.hostname;
    // console.log(`WalkMe ${isDev ? 'Dev' : 'Prod'} mode`);

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerText = `(function() {
      var walkme = document.createElement('script'); 
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = 'https://cdn.walkme.com/users/f3286453d237411794724d1f7001b574/${isDev ? 'test/' : ''
      }walkme_f3286453d237411794724d1f7001b574_https.js';
      var s = document.getElementsByTagName('script')[0];

      var username = '${username}';
      var Uptaker = ${uptaker};

      window.username = username;
      window.Uptaker = Uptaker;

      s.parentNode.insertBefore(walkme, s);
      window._walkmeConfig = {smartLoad:true};
    })();`;
    document.head.appendChild(script);
  }
  else {
    // console.log("federal product mode - not loading WalkMe");
  }
}