import Client from '@/api/client/httpClient';
import type { APIListResponse, APIResponse } from '@/api/client/client';

export default class RemoteDiagnosticsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  queryForAllActiveFaultsCount(query: string) {
    return this.instance.post(`/asset-ui/v1/faults/active/count${query}`);
  }

  queryForAllActiveFaults(query: string, data: any) {
    return this.instance.post(`/asset-ui/v1/faults/active/search${query}`, data);
  }

  queryForAssetActiveFaults(query: string, assetId: string) {
    return this.instance.post(`/asset-ui/v1/assets/${assetId}/faults/active/search${query}`);
  }

  queryForAssetActiveFaultsCount(assetId: string) {
    return this.instance.post(`/asset-ui/v1/assets/${assetId}/faults/active/count`);
  }

  queryForAssetFaultsSummaries(query: string, data: any) {
    return this.instance.post(`/asset-ui/v1/faults/active/summaries${query}`, data);
  }

  queryForAssetFaultByAssetAndFaultId(assetId: string, assetFaultId: string) {
    return this.instance.get(`/asset-ui/v1/assets/${assetId}/faults/${assetFaultId}`);
  }
}
