import { useLocalStorage } from '@/utils/localStorageUtils';
import { CoralogixRum } from '@coralogix/browser';
import useAuthStore from '@/features/auth/store/authStore';

const coralogix_enabled = import.meta.env.VITE_CORALOGIX_ENABLED;
const coralogix_api_key = import.meta.env.VITE_CORALOGIX_API_KEY;
const coralogix_app_version = import.meta.env.VITE_CORALOGIX_APP_VERSION;
const environment = import.meta.env.VITE_ENVIRONMENT;
const service_name = import.meta.env.VITE_SERVICE_NAME;

const product = useLocalStorage().product;

export default function setupCoralogix() {
  let isCoralogixEnabled = false;

  if (coralogix_enabled) {
    // console.log('coralogix_enabled is defined', coralogix_enabled);
    isCoralogixEnabled = JSON.parse(coralogix_enabled);
  }

  if (isCoralogixEnabled && product === 'commercial') {
    // console.log('Coralogix is enabled and product is commercial');
    CoralogixRum.init({
      debug: true,
      public_key: coralogix_api_key,
      application: service_name + '-' + environment,
      version: coralogix_app_version,
      coralogixDomain: 'US1',
      traceParentInHeader: {
        enabled: true,
        options: {
          propagateAwsXrayTraceHeader: true,
        }
      },
      user_context: {
        user_email: useAuthStore().getEmail,
        user_name: useAuthStore().getUserName,
        user_id: useAuthStore().getUserId,
        user_metadata: {
          tenant_id: useAuthStore().getTenantId,
          role: useAuthStore().getRole,
          impersonator: useAuthStore().getImpersonator
        }
      },
      labels: {
        tenant_id: useAuthStore().getTenantId,
        user_name: useAuthStore().getUserName,
      },
      sessionRecordingConfig: {
        enable: true,
        autoStartSessionRecording: true,
        recordConsoleEvents: true,
        sessionRecordingSampleRate: 100,
      },
    });
  } else {
    // console.log('Coralogix did NOT get initialized');
  }
}
