import { createApp } from 'vue';
import App from './App.vue';
import { buildBearerToken, checkRealm, keycloak } from '@/api/auth';
import i18n from '@/locales';
import router from './router';
import apiClient from './api/client/apiClient';
import { StorageUtils } from '@/utils/storageUtils';
import { FleetCookie } from './components/types/shared';
import { useLocalStorage } from '@/utils/localStorageUtils';
import Markdown from 'vue3-markdown-it';
import { realmSanitationUtils } from '@/utils/realmSanitationUtils';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import useAuthStore from '@/features/auth/store/authStore';
import './style/configurable-colors.css';
import { UserRole } from '@/features/admin/userManagement/types/userManagementTypes';
import '@uptake/uptake-component-library/dist/style.css'
import { installSharedComponents } from '@uptake/uptake-component-library';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App).use(pinia).use(i18n);

const isLocalDev = import.meta.env.MODE === 'development';
const isDevLikeDomain = ['fleet-dev01.uptake.com', 'fleet-stg01.uptake.com'].some(domain =>
  location.hostname.includes(domain)
);

if (isLocalDev || isDevLikeDomain) {
  (app.config as any).devtools = true;
}

useLocalStorage().product = import.meta.env.VITE_PRODUCT_MODE;
realmSanitationUtils();
checkRealm();

function checkImpersonation() {
  const jwtToken = keycloak.token;
  const authStore = useAuthStore();

  if(jwtToken) {
      try {
        const jwtTokenParsed = JSON.parse(atob(jwtToken.split('.')[1]))
        const impersonator = jwtTokenParsed.impersonator.username;
        const impersonatorId = jwtTokenParsed.impersonator.id;
        authStore.impersonator = impersonator;
        authStore.impersonatorId = impersonatorId;
      } catch {
        // console.log("token does not exist");
      }
  }
}

keycloak
  .init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    enableLogging: true,
  })
  .then(async (auth) => {
    const authStore = useAuthStore();
    if (!auth) {
      authStore.isAuthenticated = false;
      authStore.role = '';
      authStore.token = '';
    } else {
      StorageUtils.applyCookie(FleetCookie.Realm, keycloak.realm!, 0.5);
      authStore.isAuthenticated = true;
      authStore.token = buildBearerToken();
      app.config.globalProperties.$kcToken = authStore.token;
      app.config.globalProperties.$keycloak = keycloak;
      const userProfile = await keycloak.loadUserProfile();     
      checkImpersonation();
      authStore.email = userProfile.email;
      authStore.userId = userProfile.id;
      authStore.userName = userProfile.username;
      authStore.tenant = keycloak.realm;
      authStore.role = Object.values(UserRole)
        .map((x) => x as string)
        .find((x) => keycloak.realmAccess?.roles.includes(x));
      localStorage.setItem('realm', keycloak.realm!);
      StorageUtils.applyCookie(FleetCookie.Authenticated, new Date().toString());
      app.use(router);
      app.use(apiClient);
      app.use(installSharedComponents);
      app.use(Markdown);
      app.provide('keycloak', keycloak);
      app.mount('#app');
    }
  })
  .catch((error) => {
    new Error(error.message);
  });

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $style: Record<string, string>;
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $keycloak: typeof keycloak;
  }
}
